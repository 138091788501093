// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-claimants-claim-js": () => import("./../../../src/pages/claimants/claim.js" /* webpackChunkName: "component---src-pages-claimants-claim-js" */),
  "component---src-pages-claimants-js": () => import("./../../../src/pages/claimants.js" /* webpackChunkName: "component---src-pages-claimants-js" */),
  "component---src-pages-claimants-resources-js": () => import("./../../../src/pages/claimants/resources.js" /* webpackChunkName: "component---src-pages-claimants-resources-js" */),
  "component---src-pages-claimants-shelters-js": () => import("./../../../src/pages/claimants/shelters.js" /* webpackChunkName: "component---src-pages-claimants-shelters-js" */),
  "component---src-pages-claimants-support-js": () => import("./../../../src/pages/claimants/support.js" /* webpackChunkName: "component---src-pages-claimants-support-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-map-js": () => import("./../../../src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-settling-community-js": () => import("./../../../src/pages/settling/community.js" /* webpackChunkName: "component---src-pages-settling-community-js" */),
  "component---src-pages-settling-education-js": () => import("./../../../src/pages/settling/education.js" /* webpackChunkName: "component---src-pages-settling-education-js" */),
  "component---src-pages-settling-emergency-js": () => import("./../../../src/pages/settling/emergency.js" /* webpackChunkName: "component---src-pages-settling-emergency-js" */),
  "component---src-pages-settling-employment-js": () => import("./../../../src/pages/settling/employment.js" /* webpackChunkName: "component---src-pages-settling-employment-js" */),
  "component---src-pages-settling-finance-js": () => import("./../../../src/pages/settling/finance.js" /* webpackChunkName: "component---src-pages-settling-finance-js" */),
  "component---src-pages-settling-financial-aid-js": () => import("./../../../src/pages/settling/financial_aid.js" /* webpackChunkName: "component---src-pages-settling-financial-aid-js" */),
  "component---src-pages-settling-government-js": () => import("./../../../src/pages/settling/government.js" /* webpackChunkName: "component---src-pages-settling-government-js" */),
  "component---src-pages-settling-health-and-medical-js": () => import("./../../../src/pages/settling/health_and_medical.js" /* webpackChunkName: "component---src-pages-settling-health-and-medical-js" */),
  "component---src-pages-settling-housing-js": () => import("./../../../src/pages/settling/housing.js" /* webpackChunkName: "component---src-pages-settling-housing-js" */),
  "component---src-pages-settling-js": () => import("./../../../src/pages/settling.js" /* webpackChunkName: "component---src-pages-settling-js" */),
  "component---src-pages-settling-legal-aid-js": () => import("./../../../src/pages/settling/legal_aid.js" /* webpackChunkName: "component---src-pages-settling-legal-aid-js" */),
  "component---src-pages-settling-settlement-agencies-js": () => import("./../../../src/pages/settling/settlement_agencies.js" /* webpackChunkName: "component---src-pages-settling-settlement-agencies-js" */),
  "component---src-pages-settling-transportation-js": () => import("./../../../src/pages/settling/transportation.js" /* webpackChunkName: "component---src-pages-settling-transportation-js" */),
  "component---src-pages-sponsorship-js": () => import("./../../../src/pages/sponsorship.js" /* webpackChunkName: "component---src-pages-sponsorship-js" */),
  "component---src-pages-sponsorship-resettling-js": () => import("./../../../src/pages/sponsorship/resettling.js" /* webpackChunkName: "component---src-pages-sponsorship-resettling-js" */),
  "component---src-pages-sponsorship-resources-js": () => import("./../../../src/pages/sponsorship/resources.js" /* webpackChunkName: "component---src-pages-sponsorship-resources-js" */),
  "component---src-pages-welcome-js": () => import("./../../../src/pages/welcome.js" /* webpackChunkName: "component---src-pages-welcome-js" */)
}

